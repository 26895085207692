import React, { useCallback, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'react-notifications-component/dist/theme.css';
import PageLoading from '../../../shared/PageLoading';
import AbbTitle from '../../../shared/AbbSectionTitle';
import styled from 'styled-components';
import { WorkQueueGrid } from './WorkQueueGrid';
import { WorkQueueButtons } from './WorkQueueButtons';
import { CreateSerialNumberDialog } from './CreateSerialNumberDialog';
import { changeRowData, fetchWorkQueue, triggerRatingPlatePrinting, updateSelectedRows } from './WorkQueueActions';
import { RePrintRatingPlateDialog } from '../RePrintDialog/RePrintRatingPlateDialog';
import { WQActionDropdown } from './WQActionDropdown';
import { getHeaders, getRows, getShowRePrintRowDialog, isLoading } from './WorkQueueSelectors';
import { DownloadUserGuide } from '../../../shared/DownloadUserGuide';
import { getVendorId } from 'app/shared/Auth/authSelectors';

const SelectedVariant = styled.div`
  padding-bottom: 2rem;
`;

const WorkQueueButtonContainer = styled.div`
  padding-bottom: 1rem;
  display: flex;
  width: 100%;
`;
const Spacer = styled.div`
  flex-grow: 10;
`;

const WorkQueuePage = () => {
  const { variant } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector(state => isLoading(state));
  const columnsFromSAP = useSelector(state => getHeaders(state));
  const rows = useSelector(state => getRows(state));
  const showReprintDialog = useSelector(state => getShowRePrintRowDialog(state));
  const vendorId = useSelector(state => getVendorId(state));

  const showCombinedColumn = vendorId === '131805' && rows.length > 0;

  const checkedColumns = useMemo(() => {
    if (showCombinedColumn) {
      return [
        {
          key: 'combined',
          name: 'Combined',
          orderNumber: 0,
          resizable: true,
          filterable: true,
        },
        ...columnsFromSAP,
      ];
    }
    return columnsFromSAP;
  }, [columnsFromSAP, showCombinedColumn]);

  const checkedRows = useMemo(() => {
    if (showCombinedColumn) {
      return rows.map(row => ({
        ...row,
        combined: row.material && row.serialNumber ? `${row.material}${row.serialNumber}` : '',
      }));
    }
    return rows;
  }, [rows, showCombinedColumn]);

  useEffect(() => {
    dispatch(fetchWorkQueue(variant));
  }, [dispatch, variant]);

  const onRowsSelected = useCallback(
    rowIds => {
      dispatch(updateSelectedRows(rowIds));
    },
    [dispatch],
  );

  const onRowsChanged = useCallback(
    (rowIds, newData) => {
      dispatch(changeRowData({ rowIds, newData }));
    },
    [dispatch],
  );

  const reprintRatingPlateCallback = useCallback(() => {
    dispatch(triggerRatingPlatePrinting());
  }, [dispatch]);

  return (
    <>
      <PageLoading visible={loading} />
      <AbbTitle title="Work queue">
        <DownloadUserGuide filename="/user-manuals/user-guide_manufacturing-work-queues.docx" />
      </AbbTitle>

      <CreateSerialNumberDialog />
      <RePrintRatingPlateDialog yesButtonCallback={reprintRatingPlateCallback} visible={showReprintDialog} />
      <SelectedVariant>
        <span>Selected variant: {variant}</span>
        <Link to="/variant" style={{ paddingLeft: '1rem' }}>
          Change variant
        </Link>
      </SelectedVariant>
      <WorkQueueButtonContainer>
        <WorkQueueButtons workQueueName={variant} />
        <Spacer />
        <WQActionDropdown />
      </WorkQueueButtonContainer>
      <WorkQueueGrid
        loading={loading}
        gridColumns={checkedColumns}
        gridRows={checkedRows}
        variant={variant}
        onRowsSelected={onRowsSelected}
        onRowsChanged={onRowsChanged}
      />
    </>
  );
};

export default WorkQueuePage;
